import './App.css';
import { Col, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import ConnectWallet from './ConnectWallet';
import { useEtherBalance, useEthers } from '@usedapp/core';
import { useAvailableSupplyOf, useBalanceOf, useTotalSupplyOf } from './contractHooks';
import { formatEther } from '@ethersproject/units';

interface WalletBlockProps {
  id: number
}

function WalletBlock(props: WalletBlockProps) {
  const { activateBrowserWallet, account } = useEthers();
  const balance = useBalanceOf(account!, props.id);

  return (
    balance > 0 ?
      <>
        <Row>
          <Col span={12}>
            Infinity Block {props.id}
          </Col>
          <Col span={12}>
            Owned: {balance && String(balance)}
          </Col>
        </Row>
      </>
      : <></>
  )

}

function Wallet() {

  const { activateBrowserWallet, account } = useEthers();
  const etherBalance = useEtherBalance(account);

  return (
    <>
      <Row>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <ConnectWallet key={'walletConnect'} />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              Account Balance:
            </Col>
            <Col span={12}>
              {etherBalance && parseFloat(formatEther(etherBalance)).toFixed(3)} ETH
            </Col>
          </Row>

          <Title level={3}>my blocks</Title>

          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19].map(id => <WalletBlock id={id} />)}

        </Col>
      </Row>


    </>
  );
}

export default Wallet;
