import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { HashRouter } from 'react-router-dom';
import InfinityBlockRouter from './Routes';
import { DAppProvider, Polygon, Mumbai, Ropsten, Rinkeby } from '@usedapp/core';
import ReactGA from 'react-ga';
import './scss/App.scss';


const config = {
  networks: [ Polygon, Mumbai, Ropsten, Rinkeby ],
}

ReactGA.initialize("G-73F7E4YV5Q");

ReactDOM.render(
  <React.StrictMode>
    <DAppProvider config={config}>
      {/* <HashRouter basename={process.env.PUBLIC_URL}> */}
      <HashRouter>
        <InfinityBlockRouter />
      </HashRouter>
    </DAppProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
