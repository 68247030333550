import {
    Route,
    Routes,
} from "react-router-dom";
import App from "./App";
import Mint from "./Mint";

export default function InfinityBlockRouter() {
    return (
        <Routes>
            <Route path="/" element={<App />} />
            <Route path="mint" element={<Mint />} />
        </Routes>
    );
}
