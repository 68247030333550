import './App.css';
import blockImg from './block.svg';
import pyramidImg from './pyramid.svg';
import stakingImg from './staking.svg';
import blocknftImg from './blocknft.svg';
import blueprintImg from './blueprint.svg';
import builderImg from './builder.svg';
import architectImg from './architect.svg';
import { Col, Row } from 'react-bootstrap';

interface ManifestoProps {
  scrollTo?: string;
}

function Manifesto(props: ManifestoProps) {

  if (props.scrollTo) {
  }

  return (
    <div className="container-fluid" style={{ backgroundColor: 'black' }}>
      <div className="container">
        <div>
          <div>
            <div className="content-area w-90" style={{ textJustify: 'inter-word', textAlign: 'justify', lineHeight: '1.6' }}>
              <h3>MANIFESTO</h3>
              <br />

              <Row>
                <Col lg>
                  <h4>WHAT IS INFINITY BLOCK?</h4>
                  <div className='d-flex flex-row justify-content-around flex-wrap'>
                    <div className='p-3'><img src={blockImg} alt="Blocks" style={{ maxHeight: '20vh', minHeight: '150px' }} /></div>
                    <div className='w-100'>Infinity Block, Block for short, is a series of equal-sized digital cubes in a myriad of colors and textures. Each Infinity Block is an NFT in and of itself but multiple users can combine and assemble their Blocks together to create a collaborative piece. Whether in the form of a small figurine or a massive landscape, people can stake as many Blocks as they wish in the design of their choice. Once the finished product is sold in the marketplace, the sales proceeds are distributed accordingly based on the number of Blocks contributed by each member. By pitching in shares of NFT’s into collective artworks, individual Block owners can venture on more ambitious projects that require a larger number of Blocks.</div>
                  </div>
                </Col>

              </Row>

              <br />
              <h4>HOW IT WORKS</h4>
              <br />

              <Row>
                <Col md>
                  <h4>1. Minting Infinity Blocks</h4>
                  <Row>
                    We will be issuing new Infinity Blocks in phases. The first wave comprises 10,000 Genesis Blocks with special properties, which will be elaborated on in the Special Properties of Infinity Block section. Each following wave will contain Blocks in a variety of colors and textures. Full catalog of Blocks will be available on our website at all times and all purchases can be made through OpenSea.io.
                  </Row>
                  <br />
                  <Row><img src={blocknftImg} alt="Minting NFT" style={{ maxHeight: '25vh', minHeight: '100px', maxWidth: '100%' }} /></Row>
                </Col>
              </Row>


              <br />
              <Row>
                <Col md>
                  <h4>2. Roles within the Community</h4>
                  <br />
                  <Row>
                    <Col md><h5>Builders</h5>
                      <Row>
                        <img src={builderImg} alt="Builder" style={{ maxHeight: '80px', minHeight: '80px', maxWidth: '100%' }} />
                      </Row>
                      <br />
                      Any participant who owns an Infinity Block is a Builder. Builders can participate in any open project by committing to contribute at least one block.</Col>

                    <Col md>
                      <h5>Architects</h5>
                      <Row>
                        <img src={architectImg} alt="Architect" style={{ maxHeight: '80px', minHeight: '80px', maxWidth: '100%' }} />
                      </Row>
                      <br />
                      Any builder can take the role of an Architect by designing their own projects. Becoming an Architect does not eliminate one’s role as a Builder. All Architects are still Builders and can choose to participate in other projects as well.
                      <br />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <br />

              <Row>
                <Col md>
                  <h4>3. Blueprints</h4>
                  Architects can use our web-based simulator to create a Blueprint for their project. Blueprint is a mock-up of a final project that an Architect wishes to create. The simulator provides a three dimensional grid in which Shell Blocks can be assembled to make an initial render of the project. Shell Blocks are sample blocks that look just like Infinity Blocks except they’re not minted NFT’s and therefore have no value. They are mere placeholders to be replaced by Infinity Blocks later on.
                </Col>
                <Col md>
                  <img src={blueprintImg} alt="Blueprint" style={{ maxHeight: '30vh', minHeight: '100px', maxWidth: '100%' }} />
                </Col>
              </Row>
              <br />
              <Row>
                <Col lg>
                  <h4>4. Staking</h4>
                  <div className='d-flex flex-row justify-content-around flex-wrap'>
                    <div className='w-100'>To submit a Blueprint to the Infinity Block community, Architects are required to stake at least 1% (rounded up to the nearest Block) of the total number of Blocks needed to complete the respective project. Once submitted, Blueprints are published on our platform to be showcased to other Builders. Published Blueprints have a 72 hour (three day) window for Builders to stake their Blocks. Builders can freely stake or unstake Blocks in any Blueprints of their choices as long as they are still in this Staking Period.
                      <br />
                      <br />
                      During this period, Builders can also express their interest in particular Blueprints by using the “Like” button. Our website will have a Sort function to arrange Blueprints in order of the amount of Likes received, a display option which allows Builders to check out trending/popular Blueprints at a glance.
                      <br />
                      <br />
                      Builders can only stake Blocks of specific colors and textures required in the Blueprint. Architects can choose to stake more blocks in their own Blueprints as well. Any Blueprint that does not have enough Blocks committed to complete the project past the deadline are decommissioned and the staked Blocks are returned to the original hold
                    </div>

                  </div>
                </Col>
                <Row>
                  <br />
                  <Col lg>
                    <img src={stakingImg} alt="Staking" style={{ maxHeight: '40vh', minHeight: '150px', maxWidth: '100%' }} />
                  </Col>
                </Row>
              </Row>

              <br />

              <Row>
                <Col lg>
                  <h4>5. Sales</h4>
                  <div className='d-flex flex-row justify-content-around flex-wrap'>
                    <div>
                      If sufficient Blocks are committed by the deadline, the Blueprint is finalized and the staked Blocks replace Sample Blocks to create the NFT artwork that the Architect envisioned. Such artwork is called the Block Work. Builders earn their Architect’s Badge and are officially promoted as an Architect when their first Blueprint attracts enough Blocks to become a Block Work.
                      <br />
                      <br />
                      The Infinity Blocks are now locked in until the Block Work is sold in the marketplace. The floor price of the Block Work is determined by multiplying A. the total number of blocks used to create the piece, B. the average market Block price, and C. premium index 1.5. Once the project is sold, the sales proceeds will be fairly distributed back to the contributors as follows:
                      <br />
                      <br />
                      <ul>
                        <li>8% to Architects</li>
                        <li>5% to Team treasury</li>
                        <li>5% to  Community treasury</li>
                        <li>82% to Builders who staked their blocks (including the Architect), proportional to the number of Blocks they contributed</li>
                      </ul>

                      Participants of the project can also choose to donate the sales proceeds to the community pool. After the Block Work is sold, Builders have the choice to unstake their blocks from the project. These unstaked Blocks can be used again to participate in new projects.
                    </div>
                  </div>
                </Col>
              </Row>

              <br />
              <Row>
                <h4>PHILOSOPHY</h4>
                <Col lg>
                  Our ancestors piled stones to erect pyramids and churned sand to pave roads. Infatuated with the act of creation, they joined forces to devise new artifacts at every turn of their civilizations. Now, in the wake of the digital era, new breeds of architects and artists make a living by creating and trading digital properties in cyberspaces. However, not everyone has the tools or the skills to fully express their imaginations. Individual creators also take on the risk of their artworks not being properly accredited and adequately monetized. In the belief that anyone should be able to partake in the creative process and be fairly rewarded for their contributions, Infinity Block aims to provide a platform in which any number of strangers can collaborate to create, stake, vote and gain returns for their share in communal projects. Infinity Block wishes to provide building blocks with which people can build a new world.
                </Col>
                <Col sm>
                  <img src={pyramidImg} alt="Pyramid" style={{ maxHeight: '20vh', minHeight: '150px' }} />
                </Col>
              </Row>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div >);
}

export default Manifesto;
