import './App.css';
import { Button, Card, Carousel, Col, Row } from 'antd';
import Layout, { Content, Header } from 'antd/lib/layout/layout';
import Title from 'antd/lib/typography/Title';
import NavBar from './NavBar';
import { useAvailableSupplyOf, useMint, usePricePerBlock, useTotalSupply, useTotalSupply2, useTotalSupplyOf, useUri } from './contractHooks';
import { useContractFunction, useEtherBalance, useEthers, useTokenBalance } from '@usedapp/core';
import { formatEther } from "@ethersproject/units";
import ConnectWallet from './ConnectWallet';
import { decToHex } from 'hex2dec';

interface BlockToMintProps {
  id: number
}

function BlockToMint(props: BlockToMintProps) {
  const totalSupplyOf = useTotalSupplyOf(props.id);
  const availSupplyOf = useAvailableSupplyOf(props.id);
  const blockUri = useUri(props.id);
  const pricePerBlock = usePricePerBlock();

  const { state, send: mint } = useMint();

  function doMint(id: number) {
    mint(id, 1, {
      value: pricePerBlock
    });
  }

  return (
    <>

      <Card style={{ textAlign: 'center', backgroundColor: '#3EB489', lineHeight: '10vh' }}>
        <Title>Infinity Block {props.id}</Title>
        <Row>
          <Col span={12}>
            Total Supply block {props.id}:
          </Col>
          <Col span={12}>
            {totalSupplyOf && String(totalSupplyOf)}
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            Available Supply block {props.id}:
          </Col>
          <Col span={12}>
            {availSupplyOf && String(availSupplyOf)}
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            URI block {props.id}:
          </Col>
          <Col span={12}>
            {blockUri && String(blockUri)}
            {decToHex(`{id}`)}
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Button type='primary' onClick={() => doMint(props.id)}>Mint One</Button>
          </Col>
        </Row>
      </Card>

    </>
  )
}

function Mint() {

  const { activateBrowserWallet, account } = useEthers();

  const totalSupply = useTotalSupply();
  const pricePerBlock = usePricePerBlock();
  const etherBalance = useEtherBalance(account);

  return (
    <>
      <Layout style={{ background: 'black', height: '100vh' }} className="layout">
        <Header style={{ background: 'black', padding: '0' }}>
          <NavBar />
        </Header>
        <Content style={{ background: 'black', textAlign: 'left', color: '!white', paddingLeft: '5%', paddingRight: '5%', paddingTop: '2%' }}>
          <>
            <Row>

              <Col span={24}>
                <Title level={2}>mint</Title>

              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <ConnectWallet key={'mintConnect'} />
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                Price per block:
              </Col>
              <Col span={12}>
                {pricePerBlock && formatEther(pricePerBlock)} ETH
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                Account Balance:
              </Col>
              <Col span={12}>
                {etherBalance && parseFloat(formatEther(etherBalance)).toFixed(3)} ETH
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                Total Supply:
              </Col>
              <Col span={12}>
                {totalSupply && String(totalSupply)}
              </Col>
            </Row>

            <Row>
              <Col span={24}>

                <Carousel effect="fade">
                  {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19].map(id =>
                    <BlockToMint id={id} />
                  )}
                </Carousel>
              </Col>
            </Row>
          </>
        </Content>
      </Layout>
    </>
  );
}

export default Mint;
