import { constants } from './Constants';
import { ethers } from "ethers";
import { useContractCall, useContractFunction } from "@usedapp/core";
import contractAbi from "./abi/InfinityBlockContract.json";
import { Contract } from "@ethersproject/contracts";

const contractInterface = new ethers.utils.Interface(contractAbi);
console.log('contract interface is', contractInterface.functions);

const contract = new Contract(constants.TEST_CONTRACT_ADDRESS, contractInterface);

export function useMint() {
    const { state, send } = useContractFunction(contract, "mint(uint256,uint256)");
    return { state, send };
};

export function useUri(id: number) {
    const [uri]: any = useContractCall({
        abi: contractInterface,
        address: constants.TEST_CONTRACT_ADDRESS,
        method: "uri(uint256)",
        args: [id],
    }) ?? [];
    return uri;
}

export function useTotalSupply() {
    const [totalSupply]: any = useContractCall({
        abi: contractInterface,
        address: constants.TEST_CONTRACT_ADDRESS,
        method: "totalSupply()",
        args: [],
    }) ?? [];
    return totalSupply;
}

export function useTotalSupplyOf(id: number) {
    const [totalSupply]: any = useContractCall({
        abi: contractInterface,
        address: constants.TEST_CONTRACT_ADDRESS,
        method: "totalSupply(uint256)",
        args: [id],
    }) ?? [];
    return totalSupply;
}

export function useAvailableSupplyOf(id: number) {
    const [availableSupply]: any = useContractCall({
        abi: contractInterface,
        address: constants.TEST_CONTRACT_ADDRESS,
        method: "availableSupplyOf(uint256)",
        args: [id],
    }) ?? [];
    return availableSupply;
}

export function useBalanceOf(address: string, id: number) {
    const [balanceOf]: any = useContractCall({
        abi: contractInterface,
        address: constants.TEST_CONTRACT_ADDRESS,
        method: "balanceOf(address,uint256)",
        args: [address, id],
    }) ?? [];
    return balanceOf;
}

export function usePricePerBlock() {
    const [pricePerBlock]: any = useContractCall({
        abi: contractInterface,
        address: constants.TEST_CONTRACT_ADDRESS,
        method: "pricePerBlock()",
        args: [],
    }) ?? [];
    return pricePerBlock;
}



export function useTotalSupply2() {
    const { state, send } = useContractFunction(contract, "totalSupply");
    return { state, send };
}