import './App.css';
import { TwitterOutlined } from '@ant-design/icons';
import { FaDiscord } from 'react-icons/fa';
import { IconContext } from "react-icons";
import Navbar from 'react-bootstrap/Navbar';
import Modal from 'react-bootstrap/Modal';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga';
import Wallet from './Wallet';
import logo from './infinity-block-logotype-white.png';
import ConnectWallet from './ConnectWallet';
import { Link } from 'react-router-dom';

interface NavBarProps {
    manifestoRef?: MutableRefObject<null | HTMLElement>;
    roadmapRef?: MutableRefObject<null | HTMLElement>;
    teamRef?: MutableRefObject<null | HTMLElement>;
}

ReactGA.initialize("G-XEYZL8HEGX");

const NavBar = (props: NavBarProps) => {

    useEffect(() => {
        console.log('sending to ga', window.location.pathname);
        ReactGA.pageview(window.location.pathname);
    }, []);

    const [walletVisbile, setWalletVisible] = useState(false);

    return (<>
        <Modal show={walletVisbile} onHide={() => setWalletVisible(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Wallet</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Container>
                    <Row>
                        <Col>
                            <Wallet />
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>

        <nav className='navbar navbar-dark bg-primary'>

            <Navbar bg="black" variant="dark" fixed="top" expand="lg">
                <Container>
                    <Navbar.Brand href="/">
                        <img
                            alt="Infinity Block"
                            src={logo}
                            width="100"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse className="justify-content-end">
                        <Nav className="me-auto">
                            <Nav.Link onClick={() => { props.manifestoRef?.current?.scrollIntoView() }}>MANIFESTO</Nav.Link>
                            {/* <Nav.Link onClick={()=>{props.roadmapRef?.current?.scrollIntoView()}}>Roadmap</Nav.Link>
                        <Nav.Link onClick={()=>{props.teamRef?.current?.scrollIntoView()}}>Team</Nav.Link>

                        <Nav.Link href="/mint#/mint">Mint</Nav.Link>
                        <Nav.Link onClick={() => setWalletVisible(true)}>Wallet</Nav.Link> */}


                        </Nav>
                    </Navbar.Collapse>

                    <Navbar.Collapse>

                        <Nav.Link href="https://discord.gg/sSeTavTMPe" target={'_blank'} rel="noreferrer" onClick={() => {
                            ReactGA.event({
                                category: 'SOCIAL',
                                action: 'VISIT_DISCORD',
                            });
                        }}> <IconContext.Provider value={{ style: { verticalAlign: 'middle' } }}>
                                <FaDiscord />
                            </IconContext.Provider>
                        </Nav.Link>

                        <Nav.Link href="https://twitter.com/infinityblock0" target={'_blank'} rel="noreferrer" onClick={() => {
                            ReactGA.event({
                                category: 'SOCIAL',
                                action: 'VISIT_TWITTER',
                            });
                        }}>
                            <TwitterOutlined />
                        </Nav.Link>
                    </Navbar.Collapse>


                    {/* <Navbar.Text><ConnectWallet /></Navbar.Text> */}

                </Container>
            </Navbar>
        </nav>
    </>
    );
}

export default NavBar;

