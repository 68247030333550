
import block from './infinity-block-image.svg';
import blockAnimation from './block-animation.png';
import titleAnimation from './title.gif'
import blockIcon from './infinity-block-logo-white-v3.png'
import './App.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import ConnectWallet from './ConnectWallet';
import NavBar from './NavBar';
import Button from 'react-bootstrap/Button';
import Manifesto from './Manifesto';
import Roadmap from './Roadmap';
import Team from './Team';
import { useRef } from 'react';


function App() {

  const teamRef = useRef<null | HTMLElement>(null)
  const manifestoRef = useRef<null | HTMLElement>(null)
  const roadMapRef = useRef<null | HTMLElement>(null)

  return (
    <>
      <NavBar teamRef={teamRef} manifestoRef={manifestoRef} roadmapRef={roadMapRef} />

      <div className="content-wrapper">
        <div className="content-body">

          <main>
            <section className='head-area' id="main" style={{ minHeight: '90vh', textAlign: 'center' }}>
              <div className='bg-banner'></div>
              <div className="head-content container-fluid d-flex align-items-center">
                <div className="container">
                  <Row>
                    <Col md>
                      <img src={titleAnimation} alt="Everything is blocks" style={{ maxWidth: '1600px', maxHeight: '300px', width: '80%' }} onClick={() => manifestoRef?.current?.scrollIntoView()} />
                    </Col>

                    {/* <Row style={{ paddingTop: '5%' }}>
                        <Col>
                          <Button variant="outline-primary" size='sm'><Link to="/mint">mint your block now</Link></Button>
                          <ConnectWallet />
                        </Col>
                      </Row> */}
                  </Row>
                  <div style={{alignContent: 'center'}}>
                    <img src={blockIcon} style={{ maxWidth: '329px', maxHeight: '381px', width: '5%' }}  alt="Find out more" onClick={() => manifestoRef?.current?.scrollIntoView()} />
                  </div>
                </div>
              </div>
            </section>

            <section id="manifesto" ref={manifestoRef} style={{ textAlign: 'left', backgroundImage: '', color: 'white' }}>
              <Manifesto />
            </section>

            {/* <section id="roadmap" ref={roadMapRef} className="roadmap section-padding bg-color" style={{ textAlign: 'left' }}>
              <Roadmap />
            </section>

            <section id="team" ref={teamRef} style={{ textAlign: 'left' }}>
              <Team />
            </section> */}

          </main>
        </div>
      </div>
    </>
  );
}

export default App;
