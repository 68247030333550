import React, { useCallback, useEffect, useState } from 'react';
import './App.css';
import Button from 'react-bootstrap/Button';
import { useEthers } from '@usedapp/core';
import { UnsupportedChainIdError } from '@web3-react/core'
import ReactGA from 'react-ga';

declare const window: any;

ReactGA.initialize("G-XEYZL8HEGX");

function ConnectWallet() {

  const { activateBrowserWallet, account, deactivate, error, active } = useEthers();

  useEffect(() => {
    ReactGA.set({ walletId: account })
    ReactGA.event({
      category: 'WALLET',
      action: 'CONNECTED',
    });
  }, [account]);

  const [activateError, setActivateError] = useState('');

  function handleConnectWallet() {
    ReactGA.event({
      category: 'WALLET',
      action: 'ATTEMPT_CONNECT'
    });
    activateBrowserWallet();
    addOrSwitchToPreferredNetwork();
  }

  function handleDisconnectWallet() {
    deactivate();
  }

  const addOrSwitchToPreferredNetwork = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        // params: [{ chainId: "0x89" }],
        params: [{ chainId: "0x4" }],
      });
    } catch (switchError: any) {
      //  TODO: Add rinkeby testnet to metamask
    }
  };

  useEffect(() => {
    if (error !== undefined) console.log(error);

    if (error instanceof UnsupportedChainIdError) {
      console.log("activating");
      setActivateError("Switch to polygon");
    }
  }, [error]);


  return account ?
    <>
      {error}
      <Button variant="outline-secondary" className='btn-light'  size='sm' onClick={handleDisconnectWallet}> Connected: {account.slice(0, 4)}...{account.slice(
        account.length - 4,
        account.length
      )}</Button>
    </>
    :
    <Button onClick={handleConnectWallet} variant="outline-secondary" className='btn-light' size='sm'>connect wallet</Button>
}
export default ConnectWallet;